<template id="admin-users">
  <section>
    <div class="d-flex justify-content-between flex-column flex-md-row mr-md-3 align-items-center">

      <h1 class="text-center text-md-left">Users</h1>

      <b-form-input class="mb-2 ml-md-3" v-model="find" placeholder="Search......"></b-form-input>

      <b-form-select class="ml-md-3 mb-2" v-model="filterRole" @change="loadUsers">
        <option v-for="l1 of roleInFilter" :key="l1.id" :value="l1.id">{{ l1.name }}</option>
      </b-form-select>

      <div class="flex-grow-1">
        <b-form-group size="sm">
          <b-button  v-b-modal.modal-1 variant="info">
            <font-awesome-icon icon="plus"></font-awesome-icon>
            New User
          </b-button>
          <b-button  @click="loadUsers" variant="primary">
            <font-awesome-icon icon="sync"></font-awesome-icon>
            Refresh
          </b-button>
        </b-form-group>
      </div>
    </div>
    <b-card-group columns>
      <b-card v-for="u of filteredUsers" :key="u.id" :header="userHeader(u)">
        <div class="list-group">
          <div class="list-group-items">
            <font-awesome-icon :icon="['far','phone']"></font-awesome-icon>
            {{ u.mobile }}
          </div>
          <div class="list-group-items">
            <font-awesome-icon :icon="['far','user-tag']"></font-awesome-icon>
            {{ u.role }}
          </div>
          <div class="list-group-items" v-for="s of u.storeList" :key="s.id">
            <font-awesome-icon :icon="['far','store']"></font-awesome-icon>
            {{ s.name }}
          </div>
        </div>
        <template v-slot:footer>
          <div class="btn-group btn-group-sm">
            <button class="btn btn-sm btn-success" @click.prevent="editUser(u)">
              <font-awesome-icon :icon="['far','edit']"></font-awesome-icon>
            </button>
            <button class="btn btn-sm btn-danger" @click.prevent="deleteUser(u)">
              <font-awesome-icon :icon="['far','times']"></font-awesome-icon>
            </button>
          </div>
        </template>
      </b-card>
    </b-card-group>

    <b-modal id="modal-1" title="New User" @ok="save">
      <b-form>
        <b-form-group label="Name of User">
          <b-form-input v-model="user.name" type="text" required placeholder="Name"></b-form-input>
        </b-form-group>
        <b-form-group label="Mobile of User">
          <b-form-input v-model="user.mobile" type="text" required placeholder="Name"></b-form-input>
        </b-form-group>
        <b-form-group label="Role Of User">
          <b-form-select v-model="user.role" :options="regularRoles" value-field="id"
                         required
                         text-field="name"></b-form-select>
        </b-form-group>
        <b-form-group label="Allowed Store" v-if="user.role !== 'ADMIN'">
          <b-form-select v-model="user.stores" :options="stores" value-field="id"
                         multiple
                         text-field="name"></b-form-select>
        </b-form-group>
      </b-form>
    </b-modal>
  </section>
</template>
<script>
import axios from 'axios'
import { logError } from '../util'

export default {
  name: 'AdminUsers',
  data: () => ({
    stores: [],
    filterRole: 'ALL',
    users: [],
    find: '',
    user: {stores: []},

  }),
  created: function () {
    this.loadUsers()
  },
  computed: {
    regularRoles() {
      return this.$store.state.roles.filter(r => r.name !== 'CUSTOMER').filter(r => r.name !== 'ALL')
    },
    roleInFilter: function () {
      let roles = this.regularRoles.map(r => {
        return {
          id: r.id,
          name: r.name
        }
      })
      roles.unshift({id: 'ALL', name: 'ALL'})
      return roles
    },
    filteredUsers: function () {
      let searchedText = this.find.toLowerCase()
      let f = this.filterRole

      return this.users
          .filter(u => {
            if (searchedText.length === 0) {
              return true
            } else {
              return u.mobile.indexOf(searchedText) > -1 || (u.name || '').toLowerCase().indexOf(searchedText) > -1
            }
          })
          .filter(u => {
            if (f === 'ALL') {
              return true
            } else {
              return u.role === f
            }
          })
    }
  },
  methods: {
    loadUsers: function () {

      axios.get('/api/admin/areas?areaType=STORE')
          .then(json => this.stores = json.data, logError)

      axios.get('/api/admin/users')
          .then(json => {
            this.users = json.data

          }, logError)
    },
    save: function () {
      axios.post('/api/admin/users', this.user).then(() => {
        this.loadUsers()
        this.user = {stores: []}
      }, logError)
    },
    deleteUser (u) {
      axios.delete(`/api/admin/users?id=${u.id}`).then(() => {
        this.loadUsers()
        this.user = {stores: []}
      }, logError)
    },
    editUser: function (p) {
      this.user = Object.assign({}, p)
      this.$bvModal.show('modal-1')
    },
    userHeader: function (u) {
      if (!u.name)
        return u.mobile
      else return u.name.toUpperCase()
    }
  }
}
</script>
<style>
</style>