<template id="admin-expenses">
  <section>
    <div class="d-flex flex-column">
      <div class="d-flex flex-column flex-md-row justify-content-between">
        <div class="d-flex flex-column flex-md-row">
          <div class="mr-5">
            <h4>Expenses</h4>
          </div>
          <b-form-group label="From Date" class="mr-3">
            <b-form-datepicker v-model="s.fromDate"
                               class="mr-0 mr-md-3 w-100 w-md-50 mt-3"
                               value-as-date></b-form-datepicker>
          </b-form-group>
          <b-form-group label="To Date" class="mr-3">

            <b-form-datepicker v-model="s.toDate"
                               class="mr-0 mr-md-3 w-100 w-md-50 mt-3"
                               value-as-date></b-form-datepicker>
          </b-form-group>

          <div class="mt-3 align-self-center">
            <b-form-checkbox name="unpaid" v-model="s.unpaid">UnPaid Expense Only</b-form-checkbox>
          </div>
        </div>
        <div class="align-self-center mt-3 mt-md-0">
          <b-button-group size="lg">
            <b-button v-b-modal.modal-1 variant="info">
              <font-awesome-icon :icon="['far','plus']"></font-awesome-icon>
              <span class="ml-1 d-none d-md-inline">New</span>
            </b-button>
            <b-button variant="primary" @click="loadExpenses">
              <font-awesome-icon :icon="['far','sync']"></font-awesome-icon>
              <span class="ml-1 d-none d-md-inline">Update</span>
            </b-button>
          </b-button-group>
        </div>
      </div>
      <div class="d-flex mt-5 flex-column flex-md-row flex-wrap">
        <div class="card mr-md-2 mb-2" v-for="o of expenses" :key="o.id" style="min-width: 20em">
          <div class="card-header">
            <font-awesome-icon :icon="['far','user']"></font-awesome-icon>
            {{ o.paidTo }}
          </div>
          <div class="card-body">
            <div class="list-group">

              <div class="list-group-item d-flex justify-content-end">
                <span><font-awesome-icon :icon="['far','calendar']"></font-awesome-icon>
                {{ o.date }}</span>
              </div>

              <div class="list-group-item d-flex justify-content-between">
                <span>
                  <font-awesome-icon :icon="['far','download']"></font-awesome-icon>
                  {{ o.invoiceNo }}
                </span>
                <span class="ml-3">
                  <font-awesome-icon :icon="['far','calendar']"></font-awesome-icon>
                  {{ o.invoiceDt }}
                </span>

              </div>
              <div class="list-group-item d-flex justify-content-between text-success" v-if="o.paidBy || o.paymentDt">


                <span>
                  <font-awesome-icon :icon="['far','users']"></font-awesome-icon>
                  {{ o.paidBy }}
                </span>

                <span class="ml-3">
                  <font-awesome-icon :icon="['far','calendar']"></font-awesome-icon>
                  {{ o.paymentDt }}
                </span>
              </div>


              <div class="list-group-item d-flex flex-column" v-for="(li, idx) of o.lineItems"
                   :key="idx">
                <div>{{ idx + 1 }}. {{ li.name }}</div>
                <div class="d-flex justify-content-between">
                  <div>
                    {{ li.qty }} {{ li.uom }} x
                    <rupee>{{ li.price }}</rupee>
                  </div>

                  <div>
                    <rupee>{{ li.amount }}</rupee>
                  </div>
                </div>
              </div>

              <div class="list-group-item list-group-item-light text-end d-flex justify-content-between">
                <span>GST</span> <rupee>{{ o.gst }}</rupee>
              </div>
              <div class="list-group-item list-group-item-light text-end d-flex justify-content-between">
                <span>Final Invoice Value</span> <rupee>{{ o.amount }}</rupee>
              </div>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-end">
            <div class="btn-group">

              <b-button size="sm" @click="makePayment(o)" class="" variant="success" v-if="!o.paid">
                <font-awesome-icon :icon="['far','comments-dollar']"></font-awesome-icon>
              </b-button>

              <b-button size="sm" @click="edit(o)" class="" variant="primary" :disabled="o.paid">
                <font-awesome-icon icon="pencil"></font-awesome-icon>
              </b-button>

              <a v-if="o.mainImage" :href="o.mainImage" target="_blank" class="btn btn-info">
                <font-awesome-icon icon="eye"></font-awesome-icon>
              </a>
              <b-button @click="deleteExp(o)" variant="danger" :disabled="o.paid">
                <font-awesome-icon icon="trash"></font-awesome-icon>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-2" title="Make Payment" hide-footer size="xl">
      <b-form>
        <b-form-group label="To Pay">
          <b-form-input v-model="toPay.paidTo" disabled/>
        </b-form-group>
        <b-form-group label="Amount">
          <b-form-input v-model="toPay.amount" disabled/>
        </b-form-group>


        <b-form-group label="Bank AC Num">
          <b-form-input v-model="bankAcc.accNo" :disabled="bankAcc.id !== null"/>
        </b-form-group>

        <b-form-group label="Bank IFSC">
          <b-form-input v-model="bankAcc.ifsc" :disabled="bankAcc.id !== null"/>
        </b-form-group>

        <b-form-group label="Mobile Number">
          <b-form-input v-model="bankAcc.mobileNo" :disabled="bankAcc.id !== null"/>
        </b-form-group>

      <div class="d-flex justify-content-end">
        <b-button-group size="sm">
          <b-button @click="savePayment">
            Make Payment
          </b-button>
          <b-button @click="cancelPay" variant="danger">
            Cancel
          </b-button>

        </b-button-group>
      </div>

      </b-form>
    </b-modal>
    <b-modal id="modal-1" title="New Expense" @ok="save" size="xl">
      <b-form>
        <div class="row row-cols-1 row-cols-md-3">
          <div class="col">

            <b-form-group label="Invoice No">
              <b-form-input v-model="cat.invoiceNo" type="text" required placeholder="Invoice No"></b-form-input>
            </b-form-group>
          </div>
          <div class="col">

            <b-form-group label="Invoice Dt">
              <b-form-datepicker v-model="cat.invoiceDt" type="text" required
                                 placeholder="Invoice Date"></b-form-datepicker>
            </b-form-group>
          </div>
          <div class="col">

            <b-form-group label="Payment Dt">
              <b-form-datepicker v-model="cat.paymentDt" type="text" required
                                 placeholder="Invoice Date"></b-form-datepicker>
            </b-form-group>
          </div>
        </div>
        <div class="row row-cols-md-2 row-cols-1 mb-3">
          <div class="col">

            <b-input-group prepend="Supplier" class="mt-3">
              <b-form-input v-model="cat.paidTo" type="text"
                            list="vendor-list"
                            required placeholder="Paid To?"></b-form-input>
              <b-form-datalist id="vendor-list" :options="vendors"></b-form-datalist>

              <b-input-group-append>
                <b-button variant="danger" @click="clearVendor">
                  <font-awesome-icon icon="times"></font-awesome-icon>
                </b-button>
              </b-input-group-append>
            </b-input-group>

          </div>
          <div class="col">

            <b-input-group class="mt-3">
              <b-input-group-prepend is-text>
                <input type="checkbox" aria-label="Checkbox for following text input" v-model="cat.paid">
              </b-input-group-prepend>
              <b-form-input list="person-list" v-model="cat.paidBy" type="text"
                            :disabled="!cat.paid"
                            placeholder="Paid By?"></b-form-input>
              <b-form-datalist id="person-list" :options="persons"></b-form-datalist>

              <b-input-group-append>
                <b-button variant="danger" @click="clearPerson">
                  <font-awesome-icon icon="times"></font-awesome-icon>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <div class="list-group">
          <div class="list-group-item d-flex justify-content-between align-items-center align-content-center">
            <h6>Items</h6>
            <b-button variant="primary" @click="newLI" size="sm">
              <font-awesome-icon icon="plus"></font-awesome-icon>
            </b-button>
          </div>
          <div class="list-group-item" v-for="(li, idx) of cat.lineItems" :key="idx">
            <div class="d-flex flex-column flex-md-row justify-content-between align-content-center align-items-center">
              <b-form-group label="Item" class="mr-md-2">
                <div class="d-flex">
                  <b-form-input
                      list="item-list"
                      v-model="li.name" required placeholder="Name"></b-form-input>
                  <b-form-datalist id="item-list" :options="items"></b-form-datalist>
                  <b-button variant="danger" @click="clearItem(li)">
                    <font-awesome-icon icon="times"></font-awesome-icon>
                  </b-button>
                </div>
              </b-form-group>
              <b-form-group label="Qty" class="mr-md-2">
                <b-form-input v-model="li.qty"
                              @input="updateNET(li)"
                              type="number" required placeholder="Name"></b-form-input>
              </b-form-group>
              <b-form-group label="UOM" class="mr-md-2">
                <b-form-select v-model="li.uom" required :options="uoms"></b-form-select>
              </b-form-group>
              <b-form-group label="Price" class="mr-md-2">
                <b-form-input v-model="li.price" type="number" required
                              @input="updateNET(li)"
                              placeholder="Name"></b-form-input>
              </b-form-group>
              <b-form-group label="Amount" class="mr-md-2">
                <b-form-input v-model="li.amount" type="number" required placeholder="Name"></b-form-input>
              </b-form-group>

               <b-button @click="removeLI(idx)" variant="danger" size="sm">
                 <font-awesome-icon icon="times"></font-awesome-icon>
               </b-button>
            </div>
          </div>
        </div>

        <b-form-group label="GST">
          <b-form-input v-model="cat.gst" type="number" required placeholder="GST"
                        @change="updateNET(null)"></b-form-input>
        </b-form-group>

        <b-form-group label="Amount">
          <b-form-input v-model="cat.amount" type="number" required placeholder="Amount"></b-form-input>
        </b-form-group>

        <file-upload v-model="file" :resize="false" @input="addNewImage"></file-upload>
      </b-form>
    </b-modal>
  </section>
</template>
<script>
import axios from 'axios'
import Rupee from '@/components/rupee'
import FileUpload from '@/components/file-upload'
import {addDays, format} from "date-fns";
import {NewBankAcc} from "@/util";

export default {
  name: 'AdminExpenses',
  components: {Rupee, FileUpload},
  data: () => ({
    s: {
      fromDate: addDays(new Date(), -30),
      toDate: new Date(),
      unpaid: false,
    },
    vendors: [],
    accounts: [],
    items: [],
    persons: [],
    uoms: ['KG', 'NOS', 'TON', 'BAG'],
    expenses: [],
    fields: ['date', 'paidTo', 'amount', 'items', {key: 'id', label: ''}],
    cat: {
      lineItems: [
        {
          name: '',
          qty: 0,
          price: 0,
          uom: 'KG',
          amount: 0
        }
      ],
      paidTo: '',
      paidBy: '',
      paid: false,
      amount: 0,
      gst: 0,
    },
    toPay: {},
    file: '',
    bankAcc: NewBankAcc("")
  }),
  async created() {
    await this.loadExpenses()
  },
  methods: {
    async deleteExp(o) {
      await axios.delete(`/api/admin/expense?id=${o.id}`)
      await this.loadExpenses()
    },
    clearVendor() {
      this.cat.paidTo = ''
    },
    clearPerson() {
      this.cat.paidBy = ''
    },
    clearItem(li) {
      li.name = ''
    },
    updateNET(li) {
      if (li) {
        li.amount = li.qty * li.price
      }
      this.cat.amount = this.cat.lineItems.reduce((p, v) => {
        let newo = Object.assign({}, p)
        newo.amount += v.amount
        return newo
      }).amount + this.cat.gst
    },
    makePayment(p) {
      console.log('makePayment', p)
      //show bank account
      //or save bank account
      //make payment Call
      let bankAccs = this.accounts.filter(b => b.name === p.paidTo);
      if (bankAccs.length === 0) {
        this.bankAcc = NewBankAcc(p.paidTo)
      } else {
        this.bankAcc = bankAccs
      }
      this.toPay = p
      this.$bvModal.show('modal-2')
    },
    newLI() {
      this.cat.lineItems.unshift({})
    },
    removeLI(i) {
      console.log('remove', i, 1)
      this.cat.lineItems.splice(i, 1)
    },
    addNewImage(a) {
      setTimeout(() => {
        this.cat.mainImage = a
      }, 1000)
    },
    makeImgUrl: function (o) {
      return o.mainImage
    },
    edit(i) {
      this.cat = Object.assign({}, i)
      this.$bvModal.show('modal-1')
    },
    async loadExpenses() {
      let fd = format(this.s.fromDate, "yyyy-MM-dd")
      let td = format(this.s.toDate, "yyyy-MM-dd")
      this.expenses = (await axios.get(`/api/admin/expenses?fromDate=${fd}&toDate=${td}&unpaid=${this.s.unpaid}`)).data
      this.vendors = (await axios.get("/api/admin/expenses/vendors")).data
      this.items = (await axios.get("/api/admin/expenses/items")).data
      this.persons = (await axios.get("/api/admin/expenses/persons")).data
      this.accounts = (await axios.get("/api/admin/expenses/accounts")).data
    },
    async saveBankAcc() {
      await axios.post("/api/admin/expenses/accounts", this.bankAcc)
      await this.loadExpenses()
    },
    async savePayment() {
      let r = await axios.post("/api/admin/expenses/payment", {
        bankAcc: this.bankAcc,
        p: {
          id: this.toPay.id
        }
      })
      if (r.data.status) {
        this.$bvModal.hide('modal-2')
        await this.loadExpenses()
      } else {
        alert("Payment Failed")
      }
    },
    async save() {
      let exp = {
        paidTo: this.cat.paidTo,
        id: this.cat.id,
        paid: this.cat.paid,
        invoiceNo: this.cat.invoiceNo,
        invoiceDt: this.cat.invoiceDt,
        paymentDt: this.cat.paymentDt,
        paidBy: this.cat.paidBy,
        amount: this.cat.amount,
        lineItems: this.cat.lineItems,
        mainImage: this.cat.mainImage
      }
      console.log("Expenses data", exp)
      await axios.post('/api/admin/expenses', exp)
      await this.loadExpenses()
    },
    async cancelPay(){
      this.$bvModal.hide('modal-2')
    }
  }
}
</script>
<style>
</style>