<template id="store-new-order">
  <section class="p-4">
    <div class="d-flex flex-column flex-md-row mb-4">
      <b-form-group label="Brand">
        <b-form-select v-model="brandId"
                       style="width:200px"
                       :options="storeBrands"
                       :disabled="order.id !== null"
                       value-field="id" text-field="name"></b-form-select>
      </b-form-group>
      <b-form-group label="Store" class="ml-md-3">
        <b-form-select v-model="store.id"
                       style="width:200px"
                       :options="stores"
                       @change="getCustomers"
                       :disabled="order.id !== null || !brandId"
                       value-field="id" text-field="name"></b-form-select>
      </b-form-group>
      <b-form-group label="Search Customers" class="ml-md-3" style="min-width: 300px">
        <v-select :options="storeCustomers"
                  @input="selectStoreCustomer()" v-model="storeCustomer" label="displayName2"></v-select>
      </b-form-group>
      <!-- Select Customers -->
      <!-- Enter mobile number and if not found then create customer -->

    </div>
    <div class="d-flex flex-column flex-md-row">
      <h6>New Customer</h6>
      <b-form-group label="Customer Mobile Number"
                    description="Mobile number or 0000000000"
                    class="ml-0 ml-md-5">
        <b-form-input id="input-invalid"
                      :state="isValidMobile"
                      :disabled="order.id !== null || !brandId || !store.id"
                      placeholder="Enter Mobile"
                      @input="mobileEntered()"
                      maxLength="10"
                      minLength="10"
                      v-model="mobile"></b-form-input>
      </b-form-group>


      <b-form-group label="Customer Name" class="ml-0 ml-md-5">
        <b-form-input id="input-invalid"
                      :disabled="order.id !== null || !isValidMobile || !brandId || !store.id"
                      placeholder="Enter Name"
                      v-model="order.customer.name"></b-form-input>
      </b-form-group>
      <b-form-group label="Door #" class="ml-0 ml-md-5">
        <b-form-input id="input-invalid"
                      :disabled="order.id !== null || !isValidMobile || !brandId || !store.id"
                      placeholder="Door Number"
                      v-model="order.customer.house"></b-form-input>
      </b-form-group>
      <b-form-group label="Address" class="ml-0 ml-md-5">
        <b-form-textarea id="input-invalid"
                         :disabled="order.id !== null || !isValidMobile || !brandId || !store.id"
                         placeholder="Address"
                         v-model="order.customer.address"></b-form-textarea>
      </b-form-group>
      <b-form-group label="PinCode" class="ml-0 ml-md-5">
        <b-form-input id="input-invalid"
                      :disabled="order.id !== null || !isValidMobile || !brandId || !store.id"
                      placeholder="PinCode"
                      v-model="order.customer.pinCode"></b-form-input>
      </b-form-group>
    </div>

    <!-- list of selected products and totals -->
    <div class="list-group my-4">
      <div
          class="list-group-item list-group-item-primary d-flex justify-content-between align-items-center align-content-center">
        <h5>Items</h5>
      </div>
      <div class="list-group-item"
           v-for="(li, index) of order.items"
           v-bind:key="index">
        <div class="d-flex justify-content-between flex-column flex-md-row align-items-center">
          <h4>Item {{ index + 1 }}</h4>
          <b-form-group label="Product" style="min-width: 300px">
            <v-select :options="allProducts" v-model="li.product.id" :reduce="p => p.id"
                      @input="setDefVariant(li)"
                      label="name" class="" placeholder="Select Product"></v-select>
          </b-form-group>
          <b-form-group label="Variant">
            <b-form-select v-model="li.variant.id"
                           style="min-width: 200px"
                           size="lg"
                           @change="liTotal"
                           :options="variants(li)"
                           value-field="id"
                           text-field="name"
            ></b-form-select>
          </b-form-group>
          <b-form-group label="Qty">
            <b-form-spinbutton v-model="li.qty"
                               @change="liTotal"
                               size="lg" max="100" class="mb-3"></b-form-spinbutton>
          </b-form-group>
          <b-button variant="danger" size="lg"
                    @click.prevent="order.items.splice(index, 1)">
            <font-awesome-icon fixed-width :icon="['fas', 'trash']"></font-awesome-icon>
            Remove
          </b-button>
        </div>
      </div>

      <div class="list-group-item list-group-item-info d-flex flex-column flex-md-row justify-content-between align-items-center align-content-center">
        <div class="mb-2">
          <b-form-checkbox switch size="lg" name="paid" v-model="order.paidAlready">Paid?</b-form-checkbox>
        </div>
        <div class="ml-md-4 mb-2">
          <b-form-checkbox switch size="lg" name="delivered" v-model="order.alreadyDelivered">Delivered?</b-form-checkbox>
        </div>

        <div class="ml-md-4 mb-2">
          <b-form-group label="Deliver By?">
            <b-form-select size="lg" v-model="order.modeOfShipment" :options="shipmentModes" :disabled="order.alreadyDelivered"></b-form-select>
          </b-form-group>
        </div>
        <div class="ml-md-4">
          <b-form-group label="Discount">
            <b-form-input type="number" size="lg" v-model="order.discount" ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div
          class="list-group-item list-group-item-secondary d-flex justify-content-between align-items-center align-content-center">
        <h5>Total</h5>
        <em>
          <rupee>{{ order.grossValue - order.discount }}</rupee>
        </em>
      </div>
    </div>


    <div class="d-flex flex-column flex-md-row justify-content-between mb-5">

      <button class="btn btn-info flex-grow-1 m-1"
              @click.prevent="order.items.push({product:{}, qty: 1, variant: {}, amount: 0})">
        <font-awesome-icon fixed-width :icon="['fas', 'plus-square']"></font-awesome-icon>
        <br/>
        Add
      </button>

      <button class="btn btn-warning flex-grow-1 m-1"
              @click.prevent="close">
        <font-awesome-icon fixed-width :icon="['fas', 'times-hexagon']"></font-awesome-icon>
        <br/>
        Cancel
      </button>

      <button class="btn btn-success flex-grow-1 m-1" @click.prevent="saveOrder"
              :disabled="!isValidMobile || order.grossValue <= 0">
        <font-awesome-icon fixed-width :icon="['fas', 'save']"></font-awesome-icon>
        <br/>
        Save
      </button>
    </div>
  </section>
</template>
<script>
import axios from 'axios'
import Rupee from '@/components/rupee'

export default {
  name: 'StoreNewOrder',
  components: {Rupee},
  async created() {
    await this.$store.dispatch("loadBrands", "store")
    if (this.$route.params.id) {
      let json = await axios.get(`/api/orders/${this.$route.params.id}`)
      this.order.items = json.data.items.map(li => {
        return {
          product: li.product,
          variant: li.variant,
          qty: li.qty,
        }
      })
      this.order.id = json.data.id
      this.order.orderNote = json.data.orderNote
      this.order.adminNote = json.data.adminNote
      this.order.orderType = json.data.orderType
      this.order.customer.name = json.data.customer.name
      this.order.customer.house = json.data.customer.house
      this.order.customer.address = json.data.customer.address
      this.order.customer.pinCode = json.data.customer.pinCode
      this.order.customer.id = json.data.customer.id
      this.order.customer.mobile = json.data.customer.mobile
      this.mobile = json.data.customer.mobile
      this.order.deliveryArea.name = json.data.deliveryArea.name
      this.store.id = json.data.deliveryArea.id
    }
    await this.loadProducts()
  },
  async updated() {

  },
  data: () => ({
    storeCustomers: [],
    shipmentModes: ["SELF", 'WEFAST'],
    storeCustomer: null,
    mobile: '',
    brandId: '',
    store: {id: null},
    showProducts: false,
    products: [],
    stores: [],
    order: {
      customer: {},
      address: {},
      deliveryArea: {},
      orderType: 'STORE',
      modeOfShipment: 'SELF',
      samples: [],
      items: [
        {qty: 1, product: {id: null}, variant: {id: null}, amount: 0},
      ],
      id: null,
      grossValue: 0,
      discount: 0,
      paidAlready: true,
      alreadyDelivered: true,
    }
  }),
  methods: {
    selectStoreCustomer() {
      this.mobile = this.storeCustomer.mobile
      this.mobileEntered()
    },
    setDefVariant(li) {
      if (!li.product || !li.product.id) return
      let vars = this.variants(li)
      if (vars.length === 0) return
      li.variant.id = vars[0].id
      this.liTotal()
    },
    async getCustomers() {
      let d = await axios.get(`/api/store/customers?id=${this.store.id}&brandId=${this.brandId}`)
      this.storeCustomers = d.data.map(c => {
        let c1 = Object.assign({}, c)
        c1.displayName2 = `${c.name} (${c.mobile})`
        return c1
      })
    },
    variants(li) {
      if (li.product && li.product.id) {
        return this.$store.state.products
            .filter(p => p.id === li.product.id)[0].variants
      }
      return []
    },
    async loadProducts() {
      await this.$store.dispatch('loadState')
      await this.$store.dispatch('loadCategories')
      this.stores = this.$store.state.stores
      if (this.stores.length === 1) {
        this.store.id = this.stores[0].id
      }
      if (this.storeBrands.length === 1) {
        this.brandId = this.storeBrands[0].id
      }
    },
    async mobileEntered() {
      if (this.mobile.length === 10) {
        let res = await axios.get(`/api/customers?mobile=${this.mobile}&store=${this.store.id}&brand=${this.brandId}`)

        this.order.customer = {id: res.data.id, name: res.data.name, mobile: res.data.mobile}
        this.order.brand = {id: this.brandId}

        this.addressess = (await axios.get(`/api/admin/address?customer_id=${this.order.customer.id}&brand_id=${this.brandId}`)).data

        if (this.addressess.length > 0) {
          this.order.address.id = this.addressess[0].id
          this.order.address.adddress = this.addressess[0].address
        }
        this.showProducts = true

      }
    },
    async saveOrder() {
      //we will save customer here
      let unsavedCustomer = this.order.customer
      unsavedCustomer.deliveryArea = {id: this.store.id}
      unsavedCustomer.brand = {id: this.brandId}
      let savedCustomer = await axios.post(`/api/admin/customers?brandId=${this.brandId}`, unsavedCustomer)
      this.order.deliveryArea = {id: this.store.id}
      this.order.customer = {
        id: savedCustomer.data.id,
        name: savedCustomer.data.name,
        house: savedCustomer.data.house,
        address: savedCustomer.data.address,
        pinCode: savedCustomer.data.pinCode
      }
      this.order.brandId = this.brandId
      this.addressess = (await axios.get('/api/admin/address?customer_id=' + this.order.customer.id)).data
      if (this.addressess.length > 0) {
        this.order.address.id = this.addressess[0].id
        this.order.address.address = this.addressess[0].address
      }
      this.order.notifyCustomer = false
      let newOrder = await axios.post('/api/orders', this.order)
      await window.open(`${process.env.VUE_APP_URL}/api/orders/print/${newOrder.data.id}?x-auth-token=${this.$store.state.authToken}`)
      await this.$router.push('/store/orders')
    },
    async close() {
      await this.$router.push('/store/orders')
    },
    async liTotal() {
      this.order.grossValue = this.order.items
          .map((currentValue) => {
            currentValue.amount = 0

            if (currentValue.product.id) {
              let product = this.$store.state.products.find(p => p.id === currentValue.product.id)
              if (product) {
                let variant = product.variants.find(v => v.id === currentValue.variant.id)
                if (variant) {
                  currentValue.amount = (variant.offerPrice || variant.price) * currentValue.qty
                }
              }
            }
            return currentValue
          })
          .reduce((accumulator, currentValue) => {
            accumulator.amount += currentValue.amount
            return accumulator
          }).amount
    }
  },
  computed: {
    storeBrands() {
      return this.$store.state.brands.filter(b => b.hasStore)
    },
    allProducts() {
      return this.$store.state.categories
          .filter(c => c.brands.indexOf(this.brandId) > -1)
          .flatMap(c => c.products)
    },
    isAdmin: function () {
      return this.$store.state.currentUser.role === 'ADMIN'
    },
    isValidMobile: function () {
      return this.mobile.length === 10
    }
  }
}
</script>
<style>
</style>