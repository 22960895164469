<template id="payments">
  <section>
    <div class="row">
      <div class="col col-sm-6 col-md-9">
        <h4>My Payments</h4>
      </div>
      <div class="col-sm-12 col-12">
        <div class="d-flex flex-wrap">
          <div class="card bg-transparent mr-md-4" v-for="(p, index) of payments" v-bind:key="index" style="min-width: 20em">
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <div>
                                     <span class="text-success" v-if="p.verified">
                                         <font-awesome-icon :icon="['far','check-square']"></font-awesome-icon>
                                     </span>
                  <span class="text-warning" v-if="!p.verified">
                                        <font-awesome-icon :icon="['far','exclamation-square']"></font-awesome-icon>
                                    </span>
                  #{{ p.order.orderNum }}
                </div>

                <div class="text-muted">
                  <font-awesome-icon :icon="['far','calendar']"></font-awesome-icon>
                  {{ p.order.orderDateStr }}
                </div>
              </div>

            </div>
            <div class="list-group">
              <div class="list-group-item d-flex justify-content-between">
                <span>Order Value</span>
                <rupee>{{ p.order.netValue }}</rupee>
              </div>
              <div class="list-group-item d-flex justify-content-between">
                <span>Mode Of Payment </span>
                <span>
                  <b-badge>
                    {{ p.modeOfPayment }}
                  </b-badge>
                </span>
              </div>
              <div class="list-group-item d-flex justify-content-between">
                <span>Adjustments</span> <span class="text-danger">&ndash; <rupee>{{ p.deductions + p.adjustments }}</rupee></span>
              </div>
              <div class="list-group-item d-flex justify-content-between">
                <span>Received</span> <span class="text-success"><rupee>{{ p.amount }}</rupee></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from 'axios'
import Rupee from '@/components/rupee'
import {logError} from "../util";

export default {
  name: 'Payments',
  components: {Rupee},
  data: () => ({
    payments: []
  }),
  created: function () {
    this.loadpayments()
  },
  methods: {
    loadpayments: function () {
      axios.get("/api/payments")
          .then(resp => this.payments = resp.data, logError)
    }
  }
}
</script>
<style>
</style>