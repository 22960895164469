<template id="admin-customer-detail">
  <section>
    <div class="d-flex flex-column flex-wrap">
      <div class="d-flex flex-column flex-md-row mb-3 align-content-center align-items-center">
        <customer-avatar :customer="customer" size="10rem"></customer-avatar>
        <div class="d-flex flex-column ml-md-3 mt-3 mt-md-0">
          <div class="d-flex align-items-center align-content-center">

            <div class="display-5 text-center text-md-left d-flex justify-content-center justify-content-md-start">
              <display-brand v-if="customer.brand && customer.brand.id" :id="customer.brand.id"
                             :name="false"
                             :border="false"></display-brand>
              <span class="ml-1">{{ customer.name }}</span>
            </div>
            <div class="ml-3 text-large">
              <dont-disturb :customer="customer"></dont-disturb>
            </div>
          </div>
          <div class="text-center text-md-left">
            @ <span v-if="customer.deliveryArea">{{ customer.deliveryArea.name }}</span>
          </div>
          <ol class="list-unstyled">
            <li v-for="a of addresses" :key="a.id"
                class="mt-2 d-flex justify-content-between align-content-center align-items-center">
              <span>
                <font-awesome-icon icon="building"></font-awesome-icon> {{ a.fullAddress }}
              </span>
              <a @click.prevent="editCustomerAddress" class="btn btn-outline-primary btn-sm ml-3">
                <font-awesome-icon icon="edit"></font-awesome-icon>
              </a>

            </li>
          </ol>

          <div class="text-center text-md-left mt-3 mt-md-0">
            <div class="btn-group">
              <b-button variant="success" @click.prevent="sendMessage()">
                <font-awesome-icon :icon="['fab','whatsapp']"></font-awesome-icon>
              </b-button>

              <a class="btn btn-info" :href="'tel:+91'+customer.mobile">
                <font-awesome-icon :icon="['far','phone']"></font-awesome-icon>
              </a>
              <button class="btn btn-secondary" @click="copyToClipboard(customer.mobile)">
                {{ customer.mobile }}
              </button>
              <button class="btn btn-info" @click="newOrder">
                <font-awesome-icon icon="plus"></font-awesome-icon>
                New Order
              </button>
            </div>
          </div>
        </div>
      </div>
      <h1 class="text-center text-md-left mt-3">Orders</h1>
      <div class="d-flex flex-column flex-md-row flex-wrap mb-3">
        <order-display v-for="o of orders" :key="o.id" :order="o"></order-display>
      </div>

      <h1 class="text-center text-md-left mt-3">Payments</h1>
      <div class="d-flex flex-column flex-md-row flex-wrap mb-3">
        <div class="card mt-1 mr-md-3" v-for="(o, index) of payments" :key="o.id">

          <div class="card-header">
            <div class="d-flex justify-content-between">
                <span class="mr-5">
                  {{ o.date }}
                </span>

              <div class="ml-5 text-end">
                   <span class="badge " v-bind:class="{'badge-success': o.verified, 'badge-danger': !o.verified}">
                     <font-awesome-icon :icon="['far','check']" v-if="o.verified"></font-awesome-icon>
                     <font-awesome-icon :icon="['far','times']" v-else></font-awesome-icon>
                     {{ o.modeOfPayment }}
                   </span>
              </div>

            </div>
          </div>
          <div class="card-body">
            <div class="list-group list-group-flush">

              <div class="list-group-item d-flex justify-content-between align-content-center align-items-center"
                   v-bind:class="{'list-group-item-warning': o.reverted}"
                   v-if="o.modeOfPayment === 'RAZORPAY'">
                <span class="mr-5">{{ o.refNo }}</span>

                <b-button class="ml-5" variant="danger" size="sm" @click="revertPayment(o)" :disabled="o.reverted">
                  Revert
                </b-button>
              </div>
              <div class="list-group-item d-flex justify-content-end">
                <span class="text-success">
                  <rupee>{{ o.amount }}</rupee>
                </span>
              </div>
            </div>
          </div>
          <div class="card-footer" v-if="index === 0 && canRevert(o)">
            <b-button @click="revertPayment(o)">
              Revert Payment
            </b-button>
          </div>
        </div>
      </div>
      <h1 class="text-center text-md-left mt-3">Messages</h1>
      <message-display :messages="messages" :customerId="customerId"
                       v-on:message-updated="loadCustomerMessages"></message-display>
    </div>
    <b-modal id="revert-payment" hide-footer :title="revertTitle">
      <b-form @submit.prevent="reallyRevert">

        <b-form-group label="Customer">
          <b-form-input v-model="rp.customer.name" disabled></b-form-input>
        </b-form-group>
        <b-form-group label="Paid Amount">
          <b-form-input v-model="rp.amount" disabled></b-form-input>
        </b-form-group>
        <b-form-group label="Amount to Revert">
          <b-form-input v-model="revertAmt" type="number" min="1" :max="rp.amount"></b-form-input>
        </b-form-group>

        <b-button type="submit">
          Revert Payment
        </b-button>
      </b-form>
    </b-modal>
  </section>
</template>
<script>
import axios from 'axios'
import Rupee from '@/components/rupee'
import MessageDisplay from '@/components/message-display'
import CustomerAvatar from './customer-avatar'
import isAfter from 'date-fns/isAfter'
import parse from 'date-fns/parse'
import add from 'date-fns/add'
import DisplayBrand from '@/components/display-brand'
import OrderDisplay from './order-display'
import { NewDeliveryArea, NewOrder } from '@/util'
import DontDisturb from '@/components/others/DontDisturb'

export default {
  name: 'AdminCustomerDetail',
  components: {
    DontDisturb,
    OrderDisplay,
    DisplayBrand, CustomerAvatar, MessageDisplay, Rupee
  },
  data: () => ({
    payments: [],
    orders: [],
    messages: [],
    mesg: '',
    customer: {name: '', deliveryArea: {name: ''}},
    addresses: [],
    rp: {order: {}, customer: {name: ''}, amount: 0},
    revertAmt: 0,
  }),
  async created () {
    await this.loadCustomerDetails()
  },
  watch: {
    async customerUpdated (id) {
      console.log(`customer with id ${id} is updated`)
      await this.loadCustomerOrders()
      await this.loadCustomer()
    },
    async orderUpdated (id) {
      console.log(`order with id ${id} is updated`)
      await this.loadCustomerOrders()
    },
    async addressUpdated (id) {
      console.log(`address with id ${id} is updated`)
      await this.loadCustomer()
      await this.loadCustomerOrders()
    },
    async lastModifiedAt () {
      await this.loadCustomerMessages()
    },
    async customerId () {
      await this.loadCustomerDetails()
    }
  },
  computed: {
    customerUpdated () {
      return this.$store.state.customerUpdated
    },
    orderUpdated () {
      return this.$store.state.orderUpdated
    },
    addressUpdated () {
      return this.$store.state.addressUpdated
    },
    lastModifiedAt () {
      return this.$store.state.lastModifiedAt
    },
    customerId: function () {
      return this.$route.params.id
    },
    revertTitle () {
      return `Revert Payment for ${this.rp.customer.name}`
    }
  },
  methods: {
    async loadCustomerDetails () {
      await this.loadCustomer()
      await this.loadCustomerOrders()
      await this.loadCustomerPayments()
      await this.loadCustomerMessages()
    },
    async newOrder () {
      await this.$store.dispatch(
          'createOrder',
          NewOrder(NewDeliveryArea(this.customer.deliveryArea.id), this.customer.brand.id, {id: this.customerId})
      )
    },
    canRevert (o) {
      return isAfter(
          parse(o.date, 'yyyy-MM-dd', add(new Date(), {days: -1})),
          new Date()
      )
    },
    async copyToClipboard (m) {
      await navigator.clipboard.writeText(m)
    },
    async revertPayment (p) {
      this.rp = p
      this.revertAmt = 0
      this.$bvModal.show('revert-payment')
    },
    async reallyRevert () {
      await axios.delete(`/api/admin/payments/${this.rp.id}?amount=${this.revertAmt}`)
      await this.updatePage()
    },
    async updatePage () {
      await this.loadCustomerOrders()
      await this.loadCustomerPayments()
      await this.loadCustomerMessages()
    },
    async loadCustomerOrders () {
      console.log('Customer detail for', this.customerId)
      let json = await axios.get(`/api/admin/orders?customerId=${this.customerId}&time=120`)
      this.orders = json.data.orders
    },
    async loadCustomerMessages () {
      await this.$store.dispatch('loadThreads')
      let json = await axios.get(`/api/admin/message/customer/${this.customerId}`)
      this.messages = json.data
    },
    async loadCustomer () {

      let json = await axios.get(`/api/admin/customers/${this.customerId}`)
      this.customer = json.data
      this.addresses = (await axios.get(`/api/admin/address?customer_id=${this.customerId}`)).data
    },
    async loadCustomerPayments () {

      let res = await axios.get(`/api/admin/payments/${this.customerId}`)
      this.payments = res.data
    },
    async sendMessage () {
      await this.$store.dispatch('sendMessage', this.customer)
    },
    async editCustomerAddress () {
      await this.$store.dispatch('editAddress', {id: this.customer.id})
    }
  }
}
</script>
<style>
</style>